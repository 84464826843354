export const track = (eventType, opts) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`fulfillment.${eventType}`, opts);
  }
};

export const setErrorEvents = (error) => {
  try {
    const errorModel = {
      category: {
        primaryCategory: 'error',
      },
      eventInfo: {
        eventName: 'post page error',
        error: [
          {
            type: 'advisory',
            displayType: 'inline',
            pageSection: 'pip',
            code: 'Alert - PIP',
            ...error
          }
        ]
      }
    };
    if (!window.digitalData) {
      window.digitalData = {};
    }
    if (!window.digitalData.event) {
      window.digitalData.event = [];
    }
    window.digitalData.event.push(errorModel);
  } catch (err) {
    console.log(err);
  }
};