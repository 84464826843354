import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  arrayOf, bool, number, shape, string, object
} from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Drawer, DrawerItem } from '@thd-olt-component-react/drawer';
import { TileHead } from '../../TileHead';
import { StoreOnlyInfoToolTip } from './StoreOnlyInfoToolTip';
import { StoreOnlyInfoMobileDrawer } from './StoreOnlyInfoMobileDrawer';
import { formatInventory } from '../../../../helper/utils';
import { getDefaultStoreHours, showBopisDate } from '../../../../helper/timeZoneUtils';
import { IDS } from '../../../../../automationTestIds';

const StoreOnly = ({
  bopisFulfillment, itemId, isShipToHomeEligible, isAppliance, isCartTiles, pickupStoreHours
}) => {
  const { channel } = useContext(ExperienceContext);
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };
  const localStoreLocation = (bopisFulfillment?.locations || []).find(
    (location) => location.isAnchor
  );
  const quantity = localStoreLocation?.inventory?.quantity;
  const formattedInventoryQty = formatInventory(quantity);
  const [tooltipInstance, setInstance] = useState(null);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const [height, setHeight] = useState();
  const ref = useRef(null);

  const getNewHeight = () => {
    if (ref?.current) {
      const newHeight = ref.current.clientHeight;
      setHeight(newHeight);
    }
  };

  useEffect(() => {
    if (isQuickViewOpen) {
      getNewHeight();
    }
  }, [isQuickViewOpen]);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener('resize', getNewHeight);
    return () => window.removeEventListener('resize', getNewHeight);
  }, []);

  const openDrawer = (event) => {
    event.preventDefault();
    setIsQuickViewOpen(true);
  };

  const onAtYourStoreClicked = (event) => {
    if (channel === 'mobile') {
      openDrawer(event);
    } else if (channel === 'desktop') {
      if (tooltipInstance?.hide) {
        tooltipInstance.hide();
      }
    }
  };

  const renderCardDetails = () => {
    return (
      <div role="button" tabIndex={0} className="fulfillment-tile" onClick={onAtYourStoreClicked}>
        <TileHead title="At Your Store" icon="mw-icon-info" automationId={IDS.LABEL.atYourStoreLabel} />
        <div className="fulfillment__sub-title fulfillment__primary_color">
          {quantity > 0 && (
            <>
              <div
                className="u__bold pickup-timeline"
                data-testid="pickupTimeline"
                data-automation-id={IDS.ETA.atYourStoreETA}
              >
                {showBopisDate(pickupStoreHoursWithTZ, true)}
              </div>
              <div className="fulfillment-qty-row" data-automation-id={IDS.QTY.atYourStoreQty}>
                <span className="u__text--success">
                  <span>{formattedInventoryQty}</span>
                </span>
                <span>&nbsp;in stock near you, not eligible for Pickup</span>
              </div>
            </>
          )}
          {quantity === 0 && <span>Visit your store to check availability</span>}
        </div>
      </div>
    );
  };

  return channel === 'desktop' ? (
    <Tooltip
      channel={channel}
      closeButton
      placement="bottom"
      distance={-60}
      onShow={(instance) => {
        setInstance(instance);
      }}
      content={<StoreOnlyInfoToolTip />}
      interactive
    >
      <div>{renderCardDetails()}</div>
    </Tooltip>
  ) : (
    <>
      <div>{renderCardDetails()}</div>
      <Drawer
        open={isQuickViewOpen}
        onClose={() => setIsQuickViewOpen(false)}
        initialItem="mobile-quick-view"
        position="bottom"
        maxSize={height}
      >
        <DrawerItem name="mobile-quick-view">
          <StoreOnlyInfoMobileDrawer drawerRef={ref} />
        </DrawerItem>
      </Drawer>
    </>
  );
};
StoreOnly.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  isAppliance: bool,
  bopisFulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
};
StoreOnly.defaultProps = {
  isShipToHomeEligible: false,
  isAppliance: false,
  bopisFulfillment: null,
  isCartTiles: false,
  pickupStoreHours: null
};

export { StoreOnly };
