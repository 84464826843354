/* eslint-disable max-len */
import React from 'react';
import { DefaultShipping } from './partials/shipping/DefaultShipping';
import { DefaultShippingDetails } from './partials/shipping/DefaultShipping_Details';
import { ExpressDeliveryDetails } from './partials/shipping/ExpressDelivery_Details';
import { NotAvailable } from './partials/NotAvailable';
import { OffshoreSthUnavailable } from './partials/shipping/OffshoreSthUnavailable';
import { FULFILLMENT_METHODS } from '../constants';
import {
  isOutOfStockOnline,
  isStoreOnlyOOS,
  getExcludedStateName,
  isLocationInExcludedStates,
  getMaxDeliverableQty,
  getDeliveryFfmTypeToUse,
  isMockOptimize,
  getPreferredFfmFromAPI,
  isBackorderedSelection
} from '../helper/utils';

export const getDeliveryTemplate = ({
  fulfillment,
  type,
  zipCode,
  storeId,
  quantity,
  itemId,
  setShowCheckAvailability,
  pricing,
  thresholds,
  currentDeliveryFfm,
  shippingData,
  subscriptionEligible,
  preferSecondaryFFM,
  isCartTiles = false,
  fallbackMode = false,
  isSpecialOrder,
  configuratorHideQuantity,
  isConfigurableBlinds,
  disableTimer,
  disableSTH,
  disableBODFS,
  hideShippingThreshold
}) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const shippingFulfillment = !disableSTH ? (deliveryService?.services || []).find((service) => service.type === 'sth') : null;
  const shippingState = shippingFulfillment?.locations[0]?.state;
  const shippingLocation = (shippingFulfillment?.locations || []).find((location) => location?.type === 'online');
  const expressFulfillment = !disableBODFS ? (deliveryService?.services || []).find((service) => service.type === 'express delivery') : null;
  const bodfsLocation = (expressFulfillment?.locations || []).find((location) => location?.type === 'store');
  const maxInventoryAvail = getMaxDeliverableQty({ shippingLocation, bodfsLocation });
  const isBackordered = fulfillment?.backordered;
  const isBodfsGrillsAssemblyEligible = fulfillment?.bodfsAssemblyEligible || false;

  // set preferedFFM as secondary on the basis of the flag: preferSecondaryFFM
  const preferredFfmFromAPI = getPreferredFfmFromAPI(fulfillment, preferSecondaryFFM, isCartTiles, currentDeliveryFfm);

  // can tie cheapest and fast here to preferredFfmFromAPI field
  const fulfillmentToUse = getDeliveryFfmTypeToUse({
    quantity,
    shippingLocation,
    bodfsLocation,
    currentDeliveryFfm,
    // subscriptionEligible, // uncomment this line to force STH when subscription eligible
    preferredFfmFromAPI,
    pricing,
    isBackordered
  });

  const isBackorderedApplied = isBackorderedSelection(isBackordered, fulfillmentToUse);

  if (fallbackMode && !fulfillment?.sthExcludedShipState) {
    if (shippingFulfillment) {
      return {
        method: FULFILLMENT_METHODS.STH,
        enabled: true,
        quantityLimitExceed: false,
        template: <DefaultShipping
          type={shippingFulfillment?.type}
          hasFreeShipping
          testID="sth-instock"
          isSpecialOrder={isSpecialOrder}
          configuratorHideQuantity={configuratorHideQuantity}
          disableTimer={disableTimer}
          isBodfsGrillsAssemblyEligible={isBodfsGrillsAssemblyEligible}
          hideShippingThreshold={hideShippingThreshold}
        />,
        subTemplate: <DefaultShippingDetails
          itemId={itemId}
          locations={shippingFulfillment?.locations}
          storeId={storeId}
          zipCode={zipCode}
          quantity={quantity}
          shippingData={shippingData}
          isCartTiles={isCartTiles}
          fallbackMode
          isSpecialOrder={isSpecialOrder}
          isConfigurableBlinds={isConfigurableBlinds}
          disableTimer={disableTimer}
        />
      };
    }
    if (expressFulfillment) {
      return {
        method: FULFILLMENT_METHODS.BODFS,
        enabled: true,
        quantityLimitExceed: false,
        template: <DefaultShipping
          type={expressFulfillment?.type}
          totalCharge={expressFulfillment?.totalCharge}
          testID="bodfs-instock"
          isSpecialOrder={isSpecialOrder}
          configuratorHideQuantity={configuratorHideQuantity}
          isBodfsGrillsAssemblyEligible={isBodfsGrillsAssemblyEligible}
          hideShippingThreshold={hideShippingThreshold}
        />,
        subTemplate: <ExpressDeliveryDetails
          itemId={itemId}
          storeId={storeId}
          quantity={quantity}
          zipCode={zipCode}
          locations={expressFulfillment?.locations}
          isCartTiles={isCartTiles}
          fallbackMode
          isSpecialOrder={isSpecialOrder}
          isConfigurableBlinds={isConfigurableBlinds}
        />
      };
    }

  }

  if (!fulfillment?.fulfillmentOptions && (type === 'Online' || type === 'Shared')) {
    if (fulfillment?.sthExcludedShipState) {
      return {
        method: FULFILLMENT_METHODS.STH,
        enabled: false,
        excluded: true,
        template: <OffshoreSthUnavailable
          stateName={getExcludedStateName(shippingState || fulfillment?.sthExcludedShipState)}
          setShowCheckAvailability={setShowCheckAvailability}
        />,
      };
    }

    return {
      method: FULFILLMENT_METHODS.STH,
      enabled: false,
      outOfStock: true,
      showNotify: type === 'Online' || type === 'Shared',
      template: <NotAvailable type={FULFILLMENT_METHODS.STH} />,
    };
  }

  if ((shippingFulfillment && fulfillmentToUse === FULFILLMENT_METHODS.STH)
    || (fulfillment?.sthExcludedShipState && !deliveryService?.fulfillable)
  ) {
    if (!isMockOptimize() || (isMockOptimize() && !expressFulfillment)) {
      if (fulfillment?.sthExcludedShipState) {
        return {
          method: FULFILLMENT_METHODS.STH,
          enabled: false,
          excluded: true,
          template: <OffshoreSthUnavailable
            itemId={itemId}
            storeId={storeId}
            zipCode={zipCode}
            quantity={quantity}
            stateName={getExcludedStateName(fulfillment?.sthExcludedShipState)}
            setShowCheckAvailability={setShowCheckAvailability}
          />,
        };
      }

      if (isOutOfStockOnline(shippingFulfillment)) {
        return {
          method: FULFILLMENT_METHODS.STH,
          enabled: false,
          outOfStock: true,
          template: <NotAvailable type={FULFILLMENT_METHODS.STH} />,
        };
      }

      if (isLocationInExcludedStates(shippingState, fulfillment?.excludedShipStates)) {
        return {
          method: FULFILLMENT_METHODS.STH,
          enabled: false,
          excluded: true,
          template: <OffshoreSthUnavailable
            stateName={getExcludedStateName(shippingState)}
            setShowCheckAvailability={setShowCheckAvailability}
          />,
        };
      }

      if (deliveryService?.fulfillable) {
        return {
          method: FULFILLMENT_METHODS.STH,
          enabled: true,
          quantityLimitExceed: shippingLocation?.inventory?.quantity > 0
            ? shippingLocation?.inventory?.quantity < quantity
            : false,
          template: <DefaultShipping
            deliveryStartDate={shippingFulfillment?.deliveryDates?.startDate}
            deliveryEndDate={shippingFulfillment?.deliveryDates?.endDate}
            deliveryTimeline={shippingFulfillment?.deliveryTimeline}
            type={shippingFulfillment?.type}
            hasFreeShipping={shippingFulfillment?.hasFreeShipping}
            freeDeliveryThreshold={shippingFulfillment?.freeDeliveryThreshold}
            totalCharge={shippingFulfillment?.totalCharge}
            backorderedShipDate={fulfillment?.backorderedShipDate}
            backordered={fulfillment?.backordered}
            maxInventoryAvail={maxInventoryAvail}
            lineItemId={fulfillment.lineItemId}
            maxCartPriceContributor={shippingFulfillment?.maxCartPriceContributor}
            consolidatedDeliveryCharge={shippingFulfillment?.consolidatedDeliveryCharge}
            selected={shippingFulfillment?.selected}
            isCartTiles={isCartTiles}
            testID="sth-instock"
            isSpecialOrder={isSpecialOrder}
            configuratorHideQuantity={configuratorHideQuantity}
            disableTimer={disableTimer}
            isBodfsGrillsAssemblyEligible={isBodfsGrillsAssemblyEligible}
            hideShippingThreshold={hideShippingThreshold}
          />,
          subTemplate: <DefaultShippingDetails
            itemId={itemId}
            deliveryStartDate={shippingFulfillment?.deliveryDates?.startDate}
            locations={shippingFulfillment?.locations}
            dynamicEtaMinutes={shippingFulfillment?.dynamicEta?.minutes}
            dynamicEtaHours={shippingFulfillment?.dynamicEta?.hours}
            backordered={fulfillment?.backordered}
            storeId={storeId}
            zipCode={zipCode}
            quantity={quantity}
            maxInventoryAvail={maxInventoryAvail}
            shippingData={shippingData}
            isCartTiles={isCartTiles}
            isConfigurableBlinds={isConfigurableBlinds}
            isSpecialOrder={isSpecialOrder}
            disableTimer={disableTimer}
          />
        };
      }
    }
  }

  if (expressFulfillment) {
    if (deliveryService?.fulfillable) {
      return {
        method: FULFILLMENT_METHODS.BODFS,
        enabled: true,
        quantityLimitExceed: bodfsLocation?.inventory?.quantity > 0
          ? bodfsLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultShipping
          deliveryStartDate={expressFulfillment?.deliveryDates?.startDate}
          deliveryEndDate={expressFulfillment?.deliveryDates?.endDate}
          deliveryTimeline={expressFulfillment?.deliveryTimeline}
          type={expressFulfillment?.type}
          hasFreeShipping={expressFulfillment?.hasFreeShipping}
          freeDeliveryThreshold={expressFulfillment?.freeDeliveryThreshold}
          totalCharge={expressFulfillment?.totalCharge}
          backorderedShipDate={fulfillment?.backorderedShipDate}
          backordered={fulfillment?.backordered}
          maxInventoryAvail={maxInventoryAvail}
          lineItemId={fulfillment.lineItemId}
          maxCartPriceContributor={expressFulfillment?.maxCartPriceContributor}
          consolidatedDeliveryCharge={expressFulfillment?.consolidatedDeliveryCharge}
          selected={expressFulfillment?.selected}
          isCartTiles={isCartTiles}
          testID="bodfs-instock"
          isSpecialOrder={isSpecialOrder}
          configuratorHideQuantity={configuratorHideQuantity}
          isBodfsGrillsAssemblyEligible={isBodfsGrillsAssemblyEligible}
          hideShippingThreshold={hideShippingThreshold}
        />,
        subTemplate: <ExpressDeliveryDetails
          itemId={itemId}
          storeId={storeId}
          quantity={quantity}
          maxInventoryAvail={maxInventoryAvail}
          zipCode={zipCode}
          locations={expressFulfillment?.locations}
          shippingData={shippingData}
          isCartTiles={isCartTiles}
          isSpecialOrder={isSpecialOrder}
          isConfigurableBlinds={isConfigurableBlinds}
          disableSTH={disableSTH}
        />
      };
    }

    return {
      method: FULFILLMENT_METHODS.BODFS,
      enabled: false,
      outOfStock: true,
      template: <NotAvailable type={FULFILLMENT_METHODS.BODFS} />,
    };
  }

  return {
    method: FULFILLMENT_METHODS.STH,
    enabled: false,
    outOfStock: true,
    unavailable: fulfillment?.fulfillmentOptions ? isStoreOnlyOOS(fulfillment, type) : true,
    template: <NotAvailable type={FULFILLMENT_METHODS.STH} />,
  };
};
