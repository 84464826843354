import React from 'react';
import {
  string,
  shape,
  number
} from 'prop-types';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const LimitedStockByStoreNearby = ({ location }) => {
  return (
    <div className="fulfillment-tile">
      <TileHead title="Pickup Nearby" automationId={IDS.LABEL.pickupNearByLabel} />
      <div>
        <span>{`Limited stock at ${location?.storeName}`}</span>
      </div>
    </div>
  );
};

LimitedStockByStoreNearby.propTypes = {
  location: shape({
    storeName: string,
    distance: number
  }).isRequired,
};

LimitedStockByStoreNearby.defaultProps = {};

export { LimitedStockByStoreNearby };
