import React from 'react';
import { bool, string } from 'prop-types';
import { CATEGORY } from '../../constants';

const DualPath = ({ category }) => {

  const howToGetIt = () => {
    if ([CATEGORY.PLUMBING_REPAIR, CATEGORY.WATER_HEATER_REPAIR]
      .includes(category)) {
      return (
        <span className="fulfillment__unavailable--content--dual-path">
          You have selected expert in-home repair,
          so this item isn&#39;t available to add to your cart for pickup or delivery.
          One of our Pros will reach out to discuss professional repair service and pricing.
        </span>
      );
    }
    return (
      <span className="fulfillment__unavailable--content--dual-path">
        You have selected expert in-home installation,
        so this item isn&#39;t available to add to your cart for pickup or delivery.
        One of our Pros will reach out to discuss product, professional installation and pricing.
      </span>
    );
  };

  return (
    <div className="fulfillment__unavailable" data-component="FulfillmentDualPath">
      {howToGetIt()}
    </div>
  );
};

DualPath.propTypes = {
  category: string
};

DualPath.defaultProps = {
  category: ''
};

export { DualPath };
