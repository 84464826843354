import { useEffect, useCallback } from 'react';
import { FOREIGN_LIFE_CYCLE_EVENTS } from '../components/constants';

export const useFulfillmentListeners = ({
  fulfillmentModels,
  setSelectedFulfillmentMethod,
  setPreferSecondaryFFM,
  optimalFulfillmentFromAPI,
}) => {
  const changeFulfillment = useCallback((fulfillmentModel) => {
    setSelectedFulfillmentMethod(fulfillmentModel);
  }, []);

  const changeToCorrespondingFulfillment = useCallback(({ output = {} }) => {
    const targetFulfillmentType = output?.nextFulfillment;

    const isSwitchApplicable = output.currentFulfillment !== targetFulfillmentType;
    if (output.isOptedIn && isSwitchApplicable) {
      changeFulfillment(targetFulfillmentType);
      setPreferSecondaryFFM({ isServiceOptedIn: output.isOptedIn,
        prefer: targetFulfillmentType !== optimalFulfillmentFromAPI && targetFulfillmentType !== 'BOPIS' });
    }
    if (!output.isOptedIn) {
      setPreferSecondaryFFM({ isServiceOptedIn: output.isOptedIn,
        prefer: output.isOptedIn });
    }

  }, [fulfillmentModels, changeFulfillment]);

  const initializeListeners = useCallback(() => {
    LIFE_CYCLE_EVENT_BUS.on(
      FOREIGN_LIFE_CYCLE_EVENTS.productAddOns.attachLaborChange,
      changeToCorrespondingFulfillment
    );
    LIFE_CYCLE_EVENT_BUS.on(
      FOREIGN_LIFE_CYCLE_EVENTS.subscriptionAdded,
      changeToCorrespondingFulfillment
    );

  }, [changeToCorrespondingFulfillment]);

  const clearListeners = useCallback(() => {
    LIFE_CYCLE_EVENT_BUS.off(FOREIGN_LIFE_CYCLE_EVENTS.productAddOns.attachLaborChange);
    LIFE_CYCLE_EVENT_BUS.off(FOREIGN_LIFE_CYCLE_EVENTS.subscriptionAdded);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (fulfillmentModels) {
      initializeListeners();
      return clearListeners;
    }
  }, [initializeListeners, clearListeners]);
};