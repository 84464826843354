import React, { useContext } from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import { Drawer, DrawerItem, DrawerNav } from '@thd-olt-component-react/drawer';
import { CheckNearByStore } from '../CheckNearByStore';
import { CHECK_NEARBY_STORES, BOSS_DOMINANT } from '../../../../constants';
import './bossDrawer.scss';

export const BossDrawer = ({
  storeName, isQuickViewOpen, setIsQuickViewOpen, itemId, originalConfigId, addOnsAssemblyAndMore
}) => {
  const { channel } = useContext(ExperienceContext);
  let maxSize = window.matchMedia('(orientation: portrait)').matches ? 200 : 175;
  // galaxy fold in portrait
  if (window.screen.width < 300 && window.matchMedia('(orientation: portrait)').matches) { maxSize = 215; }

  return (
    <div className="BossDrawer__drawerWrapper" data-testid="boss-drawer">
      <Drawer
        open={isQuickViewOpen}
        onClose={() => setIsQuickViewOpen(false)}
        initialItem="mobile-quick-view"
        position="bottom"
        maxSize={maxSize}
      >
        <DrawerItem name="mobile-quick-view">
          <div className="BossMobileDrawer__wrapper">
            <DrawerNav backLinkTxt="">
              <span className="BossMobileDrawer__header">
                Need More?
              </span>
            </DrawerNav>
            <div className="BossMobileDrawer__divider" />
            <span className="BossMobileDrawer__body">
              {BOSS_DOMINANT.partOne} <span className="u__bold">{storeName}</span> {BOSS_DOMINANT.partTwo}
            </span>
            <span className="BossMobileDrawer__body">
              <span className="u__bold">{'Need it even sooner? '}</span>
              <CheckNearByStore
                itemId={itemId}
                originalConfigId={originalConfigId}
                addOnsAssemblyAndMore={addOnsAssemblyAndMore}
              >
                <span
                  className="u__default-link"
                  onClick={() => setIsQuickViewOpen(false)}
                  role="button"
                  tabIndex={0}
                > { CHECK_NEARBY_STORES }
                </span>
              </CheckNearByStore>
              <span>{' to see if another store has enough items in stock.'}</span>
            </span>
          </div>
        </DrawerItem>
      </Drawer>
    </div>
  );
};

BossDrawer.propTypes = {
  storeName: PropTypes.string,
  itemId: PropTypes.string,
  originalConfigId: PropTypes.string,
  isQuickViewOpen: PropTypes.bool,
  setIsQuickViewOpen: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: PropTypes.object
};

BossDrawer.defaultProps = {
  storeName: '',
  originalConfigId: null,
  itemId: null,
  isQuickViewOpen: false,
  setIsQuickViewOpen: () => {},
  addOnsAssemblyAndMore: null
};
