import React from 'react';
import PropTypes from 'prop-types';
import { DeliveryOptions } from '@thd-olt-component-react/delivery-options';
import { getDeliveryOptionsText, doesCustomerQtyExceedInventory,
  showTodayOrTomorrow } from '../../../helper/utils';
import { FULFILLMENT_METHODS } from '../../../constants';
import { InventoryWarning } from '../store/InventoryWarning';
import './defaultShippingDetails.scss';

const ExpressDeliveryZipCode = ({ zipCode }) => {
  return (
    <>
      <span>to&nbsp;</span>
      <span className="u__bold">{zipCode}</span>
    </>
  );
};

ExpressDeliveryZipCode.propTypes = {
  zipCode: PropTypes.string
};

ExpressDeliveryZipCode.defaultProps = {
  zipCode: null
};

const ExpressDeliveryDetails = ({
  itemId,
  storeId,
  quantity,
  zipCode,
  locations,
  shippingData,
  maxInventoryAvail,
  disableSTH,
  isCartTiles = false,
  fallbackMode = false,
  isSpecialOrder = false,
  isConfigurableBlinds = false,
}) => {
  const bodfsLocation = (locations || []).find((location) => location?.type === 'store');
  const customerQtyExceedInventory = doesCustomerQtyExceedInventory(maxInventoryAvail, quantity);
  const deliveryOptionsText = getDeliveryOptionsText(shippingData);

  if (isCartTiles || isConfigurableBlinds || isSpecialOrder) {
    return null;
  }

  return (
    <>
      {(!fallbackMode && zipCode && (
        <div className="delivery-options__wrapper">
          <span className="delivery-options__description-text">{deliveryOptionsText}</span>
          <div className="delivery-options__link">
            <DeliveryOptions
              itemId={itemId}
              storeId={storeId}
              zipCode={zipCode}
              quantity={quantity}
              useDrawer
              showTodayOrTomorrow={showTodayOrTomorrow}
              disableSTH={disableSTH}
            >
              Delivery Details
            </DeliveryOptions>
          </div>
        </div>
      ))}

      {customerQtyExceedInventory && !fallbackMode && (
        <InventoryWarning
          method={FULFILLMENT_METHODS.BODFS}
          location={bodfsLocation}
          maxInventoryAvail={maxInventoryAvail}
        />
      )}
    </>
  );
};

ExpressDeliveryDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string,
  quantity: PropTypes.number,
  zipCode: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    isAnchor: PropTypes.bool,
    inventory: PropTypes.shape({
      quantity: PropTypes.number,
      isInStock: PropTypes.bool,
      isLimitedQuantity: PropTypes.bool,
      isBackordered: PropTypes.bool
    })
  })),
  // eslint-disable-next-line react/forbid-prop-types
  shippingData: PropTypes.objectOf(PropTypes.any),
  maxInventoryAvail: PropTypes.number,
  isCartTiles: PropTypes.bool,
  fallbackMode: PropTypes.bool,
  isSpecialOrder: PropTypes.bool,
  isConfigurableBlinds: PropTypes.bool,
  disableSTH: PropTypes.bool
};

ExpressDeliveryDetails.defaultProps = {
  storeId: null,
  quantity: 1,
  zipCode: null,
  locations: [{
    isAnchor: false,
    inventory: {
      quantity: null,
      isInStock: false,
      isLimitedQuantity: null,
      isBackordered: false
    }
  }],
  shippingData: {},
  maxInventoryAvail: 0,
  isCartTiles: false,
  fallbackMode: false,
  isSpecialOrder: false,
  isConfigurableBlinds: false,
  disableSTH: false
};

export { ExpressDeliveryDetails };
