import React from 'react';
import PropTypes from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import dayjs from 'dayjs';
import { ShippingThreshold } from './ShippingThreshold';
import {
  formatDate,
  showTodayOrTomorrow,
  showDateOrDateRange,
  formatInventory,
} from '../../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { useAssemblyListeners } from '../../../../hooks/useAssemblyListeners';
import { FULFILLMENT_METHODS } from '../../../constants';
import { IDS } from '../../../../automationTestIds';

const DefaultShipping = ({
  deliveryStartDate,
  deliveryEndDate,
  deliveryTimeline,
  type,
  hasFreeShipping,
  freeDeliveryThreshold,
  totalCharge,
  maxInventoryAvail,
  backordered,
  lineItemId,
  maxCartPriceContributor,
  consolidatedDeliveryCharge,
  selected,
  isCartTiles,
  testID,
  isSpecialOrder,
  configuratorHideQuantity,
  isBodfsGrillsAssemblyEligible,
  hideShippingThreshold
}) => {
  const isBodfsGrillsAssemblyEnabled = useConfigService('bodfsGrillsAssemblyEnabled');
  const bodfsAssemblyEtaEnabled = useConfigService('bodfsAssemblyEtaEnabled') || false;
  const { isBODFSAssemblySelected } = useAssemblyListeners({ isBodfsGrillsAssemblyEnabled,
    isBodfsGrillsAssemblyEligible });
  const isDeliveryWithAssemblySku = type === FULFILLMENT_METHODS.EXPRESS_DELIVERY && isBODFSAssemblySelected;
  // for adding 2 days to deliveries that are BODFS and have pre assembly selected
  const deliveryStartDateAdj = bodfsAssemblyEtaEnabled && isDeliveryWithAssemblySku
    ? dayjs(deliveryStartDate).add(2, 'day').format('YYYY-MM-DD')
    : deliveryStartDate;
  const deliveryEndDateAdj = bodfsAssemblyEtaEnabled && isDeliveryWithAssemblySku
    ? dayjs(deliveryEndDate).add(2, 'day').format('YYYY-MM-DD')
    : deliveryEndDate;

  const formattedStartDate = formatDate(deliveryStartDateAdj);
  const formattedEndDate = formatDate(deliveryEndDateAdj);
  const formattedInventoryQty = formatInventory(maxInventoryAvail);

  const showDeliveryStartDate = deliveryStartDateAdj || deliveryEndDateAdj;

  const renderDeliveryStartDate = () => {
    if ((!bodfsAssemblyEtaEnabled && type === FULFILLMENT_METHODS.EXPRESS_DELIVERY && isBODFSAssemblySelected)
    || !showDeliveryStartDate) {
      return (
        <div className="u__bold delivery-timeline" data-automation-id={IDS.ETA.sthETA}>
          &nbsp;
        </div>
      );
    }

    if (formattedStartDate === formattedEndDate) {
      const timelineAdj = isBODFSAssemblySelected && isDeliveryWithAssemblySku ? 'later' : deliveryTimeline;
      return (
        <div className="u__bold delivery-timeline" data-automation-id={IDS.ETA.sthETA}>
          {showTodayOrTomorrow(deliveryEndDateAdj, true, timelineAdj, formattedEndDate)}
        </div>
      );
    }

    return (
      <div className="u__bold delivery-timeline" data-automation-id={IDS.ETA.sthETA}>
        {showDateOrDateRange({ formattedStartDate, formattedEndDate })}
      </div>
    );
  };

  return (
    <div className="fulfillment-tile" data-testid={testID}>
      <TileHead
        title="Delivery"
        automationId={IDS.LABEL.sthLabel}
      />
      <div className="fulfillment__sub-title">
        {renderDeliveryStartDate()}
        {backordered && type === FULFILLMENT_METHODS.STH_FFM ? (
          <div className="fulfillment-qty-row" data-automation-id={IDS.QTY.sthQty}>Backordered</div>
        ) : ((!!formattedInventoryQty) && (!isSpecialOrder && !configuratorHideQuantity)) && (
          <div className="fulfillment-qty-row" data-automation-id={IDS.QTY.sthQty}>
            <span className="u__text--success">{formattedInventoryQty}</span> available
          </div>
        )}
      </div>
      <TileFooter>
        { !hideShippingThreshold
        && (
          <ShippingThreshold
            type={type}
            hasFreeShipping={hasFreeShipping}
            freeDeliveryThreshold={freeDeliveryThreshold}
            totalCharge={totalCharge}
            lineItemId={lineItemId}
            maxCartPriceContributor={maxCartPriceContributor}
            consolidatedDeliveryCharge={consolidatedDeliveryCharge}
            selected={selected}
            isCartTiles={isCartTiles}
          />
        )}
      </TileFooter>
    </div>
  );
};

DefaultShipping.propTypes = {
  deliveryStartDate: PropTypes.string,
  deliveryEndDate: PropTypes.string,
  deliveryTimeline: PropTypes.string,
  type: PropTypes.string,
  hasFreeShipping: PropTypes.bool,
  freeDeliveryThreshold: PropTypes.number,
  totalCharge: PropTypes.number,
  maxInventoryAvail: PropTypes.number,
  backordered: PropTypes.bool,
  lineItemId: PropTypes.string,
  maxCartPriceContributor: PropTypes.string,
  consolidatedDeliveryCharge: PropTypes.number,
  selected: PropTypes.bool,
  isCartTiles: PropTypes.bool,
  testID: PropTypes.string,
  isSpecialOrder: PropTypes.bool,
  configuratorHideQuantity: PropTypes.bool,
  isBodfsGrillsAssemblyEligible: PropTypes.bool,
  hideShippingThreshold: PropTypes.bool
};

DefaultShipping.defaultProps = {
  deliveryStartDate: null,
  deliveryEndDate: null,
  deliveryTimeline: null,
  type: null,
  hasFreeShipping: false,
  freeDeliveryThreshold: null,
  totalCharge: null,
  maxInventoryAvail: null,
  backordered: false,
  lineItemId: null,
  maxCartPriceContributor: null,
  consolidatedDeliveryCharge: null,
  selected: false,
  isCartTiles: false,
  testID: '',
  isSpecialOrder: false,
  configuratorHideQuantity: false,
  isBodfsGrillsAssemblyEligible: false,
  hideShippingThreshold: false
};

export { DefaultShipping };
