export const IDS = {
  LABEL: {
    sthLabel: 'sthLabel',
    pickupLabel: 'pickupLabel',
    stsLabel: 'stsLabel',
    bopisLabel: 'bopisLabel',
    bodfsLabel: 'bodfsLabel',
    pickupNearByLabel: 'pickupNearByLabel',
    limitedStockLabel: 'limitedStockLabel',
    atYourStoreLabel: 'atYourStoreLabel',
    deliveryToLabel: 'deliveryToLabel'
  },
  ETA: {
    sthETA: 'sthETA',
    pickupETA: 'pickupETA',
    stsETA: 'stsETA',
    bopisETA: 'bopisETA',
    bodfsETA: 'bodfsETA',
    pickupNearByETA: 'pickupNearByETA',
    atYourStoreETA: 'atYourStoreETA',
  },
  QTY: {
    sthQty: 'sthQty',
    pickupQty: 'pickupQty',
    stsQty: 'stsQty',
    bopisQty: 'bopisQty',
    bodfsQty: 'bodfsQty',
    pickupNearByQty: 'pickupNearByQty',
    atYourStoreQty: 'atYourStoreQty',
  },
  UA: {
    sth_unavailable: 'sth_unavailable',
    pickup_unavailable: 'pickup_unavailable',
    sts_unavailable: 'sts_unavailable',
  }
};
