/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

export const DeliveryTile = ({
  getWrapperClasses,
  fulfillmentObj,
  currentFulfillmentMethod,
  setSelectedFulfillmentMethod
}) => {

  return (
    <>
      <button
        type="button"
        className={getWrapperClasses(fulfillmentObj, true)}
        onClick={() => {
          if ((fulfillmentObj.method === currentFulfillmentMethod) || !fulfillmentObj.enabled) return;
          setSelectedFulfillmentMethod(fulfillmentObj.method);
        }}
      >
        {fulfillmentObj.template}
      </button>
    </>
  );
};

DeliveryTile.propTypes = {
  getWrapperClasses: PropTypes.func.isRequired,
  fulfillmentObj: PropTypes.object.isRequired,
  currentFulfillmentMethod: PropTypes.string.isRequired,
  setSelectedFulfillmentMethod: PropTypes.func.isRequired
};

DeliveryTile.defaultProps = {};
