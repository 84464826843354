import React from 'react';
import './storeOnlyMobileDrawer.scss';
import { DrawerNav } from '@thd-olt-component-react/drawer';
import { shape, object } from 'prop-types';

const StoreOnlyInfoMobileDrawer = ({ drawerRef }) => (
  <div className="StoreOnlyMobileDrawer__wrapper" ref={drawerRef}>
    <DrawerNav backLinkTxt="">
      <span className="StoreOnlyMobileDrawer__header">Not Eligible for Order Pickup</span>
    </DrawerNav>
    <div className="StoreOnlyMobileDrawer__divider" />
    <span className="StoreOnlyMobileDrawer__body">
      While this item is in stock at a store near you, it is not eligible for Order Pickup. Visit
      your local Home Depot to purchase in store.&nbsp;
    </span>
  </div>
);

StoreOnlyInfoMobileDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawerRef: object
};

StoreOnlyInfoMobileDrawer.defaultProps = {
  drawerRef: null,
};

export { StoreOnlyInfoMobileDrawer };
